import Duck from './components/Duck';
import Main from './modules/Main';

function App() {
  return (
    <div className='wrapper'>
      {/* <Header /> */}
      <Duck />

      <div className='page'>
        <Main />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default App;
