import style from './Duck.module.scss';
import img from './assets/duck.png';
import imgVoice from './assets/duck-voice.png';
import { useEffect, useRef, useState } from 'react';
import audio from './assets/audio.mp3';
import { useMediaQuery } from 'usehooks-ts';

const posArr = [
  {
    top: '-200px',
    left: '0px',
    right: 'auto',
    bottom: 'auto',
    rotate: '90',
  },
  {
    top: '-200px',
    left: 'auto',
    right: '0px',
    bottom: 'auto',
    rotate: '180',
  },
  {
    top: '0px',
    left: 'auto',
    right: '0px',
    bottom: 'auto',
    rotate: '270',
  },
  {
    top: 'auto',
    left: 'auto',
    right: '-75px',
    bottom: '-150px',
    rotate: '300',
  },
  {
    top: 'auto',
    left: 'auto',
    right: '175px',
    bottom: '-200px',
    rotate: '0',
  },
  {
    top: 'auto',
    left: '0px',
    right: 'auto',
    bottom: '-200px',
    rotate: '0',
  },
  {
    top: 'auto',
    left: '-125px',
    right: 'auto',
    bottom: '-125px',
    rotate: '25',
  },
];
const posArrMob = [
  {
    top: '-100px',
    left: '0px',
    right: 'auto',
    bottom: 'auto',
    rotate: '90',
  },
  {
    top: '-100px',
    left: 'auto',
    right: '0px',
    bottom: 'auto',
    rotate: '180',
  },
  {
    top: '0px',
    left: 'auto',
    right: '0px',
    bottom: 'auto',
    rotate: '270',
  },
  {
    top: 'auto',
    left: 'auto',
    right: '-75px',
    bottom: '-75px',
    rotate: '300',
  },
  {
    top: 'auto',
    left: 'auto',
    right: '75px',
    bottom: '-100px',
    rotate: '0',
  },
  {
    top: 'auto',
    left: '0px',
    right: 'auto',
    bottom: '-100px',
    rotate: '0',
  },
  {
    top: 'auto',
    left: '-125px',
    right: 'auto',
    bottom: '-125px',
    rotate: '25',
  },
];

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const Duck = () => {
  const isTablet = useMediaQuery('(max-width:991px)');
  const audioRef = useRef(null);
  const [firstInteraction, setFirstInteraction] = useState(false);
  const [posIndex, setPosIndex] = useState(getRandomInt(posArr.length - 1));
  const [isVoicePlayed, setIsVoicePlayed] = useState(false);

  const initVoice = () => {
    setFirstInteraction(true);
    audioRef.current.play();
    setIsVoicePlayed(true);

    setTimeout(() => {
      setIsVoicePlayed(false);
    }, 300);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setPosIndex(getRandomInt(posArr.length - 1));
      firstInteraction && initVoice();
    }, 5000);

    return () => clearInterval(timer);
  }, [firstInteraction]);

  useEffect(() => {
    document.addEventListener('click', () => {
      initVoice();
    });
  }, []);

  return (
    <>
      <audio
        ref={audioRef}
        controls
        className={style.audio}
        src={audio}
        webkit-playsinline='true'
        playsinline='true'
      ></audio>
      <div
        className={style.duck}
        style={
          isTablet
            ? {
                top: `${posArrMob[posIndex].top}`,
                left: `${posArrMob[posIndex].left}`,
                right: `${posArrMob[posIndex].right}`,
                bottom: `${posArrMob[posIndex].bottom}`,
                transform: `rotate(${posArrMob[posIndex].rotate}deg)`,
              }
            : {
                top: `${posArr[posIndex].top}`,
                left: `${posArr[posIndex].left}`,
                right: `${posArr[posIndex].right}`,
                bottom: `${posArr[posIndex].bottom}`,
                transform: `rotate(${posArr[posIndex].rotate}deg)`,
              }
        }
      >
        {isVoicePlayed ? (
          <img src={imgVoice} alt='' />
        ) : (
          <img src={img} alt='' />
        )}
      </div>
    </>
  );
};

export default Duck;
